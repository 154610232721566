@import 'media-query.mixins';

:root {
  --_footerPosition: initial;
  --_footerDisplay: flex;
  --_footerFlexFlow: row wrap;
  --_footerHeight: 80px;
  --_footerHeightMobile: 80px;
  --_footerLinksColor: var(--textColor);
  --_footerPadding: 0;
  --_footerPaddingTop: initial;
  --_footerTextAlign: center;
  --_footerMargin: 0;
  --_footerLinksFontSize: var(--textSize);
  --_footerLinksFontSizeMobile: var(--textSize);
  --_footerLogoDisplay: flex;
  --_footerSocialLinksWidth: auto;
  --_footerSocialLinksJustifyContent: flex-start;
  --_footerSocialBackground: var(--backgroundColor);
  --_footerSocialColor: var(--footerLinksColor);
  --_footerSocialWidth: 45px;
  --_footerSocialHeight: 45px;
  --_footerSocialBorderRadius: 50%;
  --_footerSocialBorder: var(--borders);
  --_footerSocialFontSize: 18px;
  --_footerMenuWrapPosition: initial;
  --_footerMenuWrapWidth: initial;
  --_footerMenuWrapMargin: initial;
  --_footerMenuWrapPositionMobile: var(--footerMenuWrapPosition);
  --_footerMenuWrapWidthMobile: var(--footerMenuWrapWidth);
  --_footerMenuWrapMarginMobile: var(--footerMenuWrapMargin);
  --_footerUsheruLogoWidth: initial;
  --_footerUsheruLogoMargin: initial;
  --_footerBackground: var(--backgroundColor);
  --_footerSubscribeFormMargin: 20px auto;
  --_footerSubscribeFormPadding: 10px 0 0 0;
  --_footer-link-hover-text-decoration: unset;
  --_footer-link-hover-text-decoration-color: unset;
  --_footer-link-hover-text-decoration-thickness: unset;
  --_footer-link-hover-text-underline-offset: unset;
  --_footer-link-custom-border-display: unset;
}

footer {
  display: var(--footerDisplay, var(--_footerDisplay));
  flex-flow: var(--footerFlexFlow, var(--_footerFlexFlow));
  transition: background-color 1s ease-in;
  margin: var(--footerMargin, var(--_footerMargin));
  padding: var(--footerPadding, var(--_footerPadding));
  text-align: var(--footerTextAlign, var(--_footerTextAlign));
}

#footer-container {
  display: flex;
  flex-direction: column;
}

#footer,
#footer ul.footer-menu {
  column-gap: var(--footer-column-gap, 20px);
  row-gap: var(--footer-row-gap, 20px);
}

.footer-wrapper {
  display: flex;
  align-items: center;
  background-color: var(--footerBackground, var(--_footerBackground));
  padding: var(--footer-wrapper-padding, 0);
}

#footer {
  display: var(--footer-items-display, flex);
  position: var(--footerPosition, var(--_footerPosition));
  flex-flow: var(--footer-items-flex-flow, row);
  justify-content: var(--footer-justify-content, center);
  align-items: center;
  margin: 0 auto;
  padding-top: var(--footerPaddingTop, var(--_footerPaddingTop));
  width: var(--contentWidth);
  max-width: $xxl;
  font-size: var(--footerLinksFontSizeMobile, var(--_footerLinksFontSizeMobile));
  @include for-tablet-portrait-up {
    justify-content: var(--footer-justify-content, space-between);
  }

  .footer-logo {
    display: var(--footerLogoDisplay, var(--_footerLogoDisplay));
    justify-content: var(--footer-logo-justify-content, center);
    order: var(--footer-logo-order, initial);
    width: var(--footer-logo-width, auto);

    @media all and (min-width: $md) {
      justify-content: var(--footer-logo-justify-content-md, flex-start);
    }
  }

  @media screen and (min-width: $md) {
    ul.footer-menu {
      justify-content: center;
    }
  }
  @media all and (min-width: $lg) {
    ul.footer-menu {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: center;
      height: 100%;
      li {
        //margin-left: 0;
      }
    }
  }

  @media all and (max-width: $lg - 1) {
    & > * {
      justify-content: space-between;
    }
  }

  &,
  & i,
  & a {
    color: var(--footerLinksColor, var(--_footerLinksColor));
  }

  & > * {
    flex-shrink: 0.6;
    padding-bottom: var(--footer-child-padding-bottom, 20px);
  }

  ul,
  ul li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .footer-menu {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    height: 100%;

    @media screen and (min-width: $md) {
      justify-content: flex-end;
    }
  }

  li {
    a:not(.social-link) {
      display: block;
      margin: 0 auto;
      width: max-content;
    }
  }

  .footer-menu a {
    width: max-content;
    &:hover {
      .border {
        transform: scaleX(1);
      }
    }
    .border {
      display: var(--footerLinkCustomBorderDisplay, var(--_footerLinkCustomBorderDisplay));
      transform: scaleX(0);
      transition: 0.4s transform;
      border-radius: var(--borderRadius);
      background-color: whitesmoke;
      width: 100%;
      height: 2px;
    }
  }

  .footer-menu {
    margin: auto 0;

    & > * {
      flex: 1 1;
      &:last-child {
        margin-right: 0;
      }
    }

    @media all and (min-width: $lg) {
      & > * {
        margin-right: 0;
      }
    }

    @media all and (max-width: $sm - 1) {
      margin: 0;
    }
  }

  .footer-icon {
    height: 50px;
  }
}

.footer-right {
  justify-content: space-between;

  @media all and (min-width: $lg) {
    justify-content: flex-end;
  }
}

#footer-subscribe-form {
  width: 100%;
}
:host ::ng-deep {
  #footer-subscribe-form {
    margin: var(--footerSubscribeFormMargin, var(--_footerSubscribeFormMargin));
    padding: var(--footerSubscribeFormPadding, var(--_footerSubscribeFormPadding));
    ush-showtime-streaming-subscribe {
      .subscribe-form {
        margin: 0;
        border-radius: 0;
      }

      @include for-tablet-landscape-up {
        .subscribe-form-content {
          display: flex;
          .subscribe-title {
            max-width: 50%;
            text-align: center;
          }
        }
      }
    }
  }
}

#footer ush-social-links .social-links {
  justify-content: var(--footerSocialLinksJustifyContent, var(--_footerSocialLinksJustifyContent));
}

.footer-menu-wrap {
  position: var(--footerMenuWrapPosition, var(--_footerMenuWrapPosition));
  grid-row: var(--foooter-menu-wrap-grid-row, unset);
  grid-column: var(--footer-menu-wrap-grid-column, unset);
  order: var(--footer-menu-wrap-order, initial);
  margin: var(--footerMenuWrapMargin, var(--_footerMenuWrapMargin));
  width: var(--footerMenuWrapWidth, var(--_footerMenuWrapWidth));

  @include for-tablet-landscape-down {
    position: var(--footerMenuWrapPositionMobile, var(--_footerMenuWrapPositionMobile));
    margin: var(--footerMenuWrapMarginMobile, var(--_footerMenuWrapMarginMobile));
    width: var(--footerMenuWrapWidthMobile, var(--_footerMenuWrapWidthMobile));
  }
}

#footer {
  #langSelect {
    margin-top: -5px;
    font-size: var(--footerLinksFontSize, var(--_footerLinksFontSize));
  }
  .footer-icon {
    height: 40px;
  }
  & > * {
    @include for-tablet-landscape-up {
      width: auto;
      font-size: var(--footerLinksFontSize, var(--_footerLinksFontSize));
    }
  }

  ush-usheru-logo {
    order: var(--footer-usheru-logo-order, initial);
    margin: var(--footerUsheruLogoMargin, var(--_footerUsheruLogoMargin));
    width: var(--footerUsheruLogoWidth, var(--_footerUsheruLogoWidth));
  }

  ush-social-links {
    grid-row: var(--foooter-social-links-grid-row, unset);
    grid-column: var(--footer-social-links-grid-column, unset);
    order: var(--footer-social-links-order, initial);
    width: var(--footerSocialLinksWidth, var(--_footerSocialLinksWidth));

    &::ng-deep fa-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.4s border-color;
      border: var(--footerSocialBorder, var(--_footerSocialBorder));
      border-radius: var(--footerSocialBorderRadius, var(--_footerSocialBorderRadius));
      width: var(--footerSocialWidth, var(--_footerSocialWidth));
      height: var(--footerSocialHeight, var(--_footerSocialHeight));
      color: var(--footerLinksColor, var(--_footerLinksColor));
      font-size: var(--footerSocialFontSize, var(--_footerSocialFontSize));
    }
  }

  .footer-menu .nav-link {
    &:is(:hover, :focus) {
      text-decoration: var(--footer-link-hover-text-decoration, var(--_footer-link-hover-text-decoration));
      text-decoration-color: var(
        --footer-link-hover-text-decoration-color,
        var(--_footer-link-hover-text-decoration-color)
      );
      text-decoration-thickness: var(
        --footer-link-hover-text-decoration-thickness,
        var(--_footer-link-hover-text-decoration-thickness)
      );
      text-underline-offset: var(
        --footer-link-hover-text-underline-offset,
        var(--_footer-link-hover-text-underline-offset)
      );
    }
  }
}
